import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from "axios";
import dayjs from "dayjs";
import Spinner from 'react-bootstrap/Spinner';
import withReactContent from 'sweetalert2-react-content'
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";


function Reports(){
  const MySwal = withReactContent(Swal)
  const [cust_info, setcust] = useState([]);
  const [regions, setRegions]=useState([])
  const [done, setDone]=useState("")
  // const baseUrl = "http://localhost:9650/api/fetchBookingData";
  const baseUrl = "https://cci.starlightsoftware.co.bw/api/fetchBookingData";
  const regionUrl="https://cci.starlightsoftware.co.bw/api/fetchRegions";

  const [state , setState] = useState({
    "month": '',
    "year": '',
  })

  const getMonthYear=()=>{
    const months = [
      { id: 1, name: "January" },
      { id: 2, name: "February" },
      { id: 3, name: "March" },
      { id: 4, name: "April" },
      { id: 5, name: "May" },
      { id: 6, name: "June" },
      { id: 7, name: "July" },
      { id: 8, name: "August" },
      { id: 9, name: "September" },
      { id: 10, name: "October" },
      { id: 11, name: "November" },
      { id: 12, name: "December" }
    ];
    const date=new Date();
    const month=months.filter(res=>res.id==date.getMonth()+1);
    const year=date.getFullYear();
    return (
      {
      month:date.getMonth()+1,
      year
    })
    //make call to load      

  }
    const handleChange = (e) => {
      const {id , value} = e.target   
      setState(prevState => ({
          ...prevState,
          [id] : value
      }))
    }

  
      
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalPassengers, setTotalPassengers] = useState(0);
    const token = localStorage.getItem('ccicanadaaccesstoken')
  
  let axiosConfig = {
  headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
  }
  };
  

        useEffect(()=>{
            axios.post(regionUrl,{
              "accesstoken": token,
              "pickupUrl": ""
              },axiosConfig).then((res)=>{
                setRegions(res.data.data);
                generatetable(null,res.data.data)
              }).catch((err)=>console.log(err))
        },[])

  const generatetable = (e,reg=null) => {
    const gen=getMonthYear();
    //console.log(state.month);
    e?.preventDefault();
    setState(gen)
    var body = {
      "accesstoken": token,
      "month": !state.month?gen.month:state.month,
      "year": !state.year?gen.year:state.year
      };
    console.log(body);
    
    // if (!state.month || !state.year ) {
    //   MySwal.fire({
    //     title: <p>Please fill all fields</p>,
    //     didOpen: () => {},
    //   }).then(() => {});
    // } else {
      MySwal.fire({
        didOpen: () => {
          MySwal.showLoading();
          axios.post(baseUrl, body, axiosConfig)
            .then((response) => {
              console.log("response received: ", response);
              let sortedArray;
              if(response.data.data){
                response.data.data.forEach((resp,i)=>{
                  if(resp.bookingregion!=""){
                  const regi=regions.length==0?reg:regions
                  const filter=regi.filter(res=>res.id==resp.bookingregion);
                  response.data.data[i]['bookingregion']=filter[0].region_name;
                  }
                });
                sortedArray = response.data.data;
                
              }
              setcust(sortedArray);
              console.log(sortedArray);
  
              // Calculate total sum of total_fare
              const totalAmount = sortedArray.reduce((sum, item) => {
                return typeof item.total_fare === 'number' ? sum + item.total_fare : sum;
              }, 0);
              const totalPassengers = sortedArray.reduce((sum, item) => {
                return typeof item.total_passengers === 'number' ? sum + item.total_passengers : sum;
              }, 0);
  
              setTotalAmount(totalAmount);
              setTotalPassengers(totalPassengers)
  
              MySwal.fire({
                title: <p>Table Generated</p>,
                didOpen: () => {},
              });
            })
            .catch((err) => {
              console.log("AXIOS ERROR: ", err);
              return MySwal.fire(err.response.data.message);
            });
        },
      }).then(() => {
        // Swal.close()
      });
   // }
  };
  
  
        const columns = [
                {
                  name: 'LOCATION',
                  selector: (row) =>  row.bookingregion,
                  sortable: true,
                  width: "12rem",
                  cellExport: (row) =>  row.bookingregion
              },
  
              {
                name: 'TOTAL AMOUNT',
                selector: (row) => row.total_fare,
                sortable: true,
                width: "12rem",
              cellExport: (row) => row.total_fare
            },
            {
              name: 'TOTAL PASSENGERS',
              selector: (row) => row.total_passengers,
              sortable: true,
              width: "12rem",
            cellExport: (row) => row.total_passengers
          },
  
          ];

            const data=cust_info;
            const tableData = {
              columns,
              data
            };
      
           
  

    

      return (
     <div>
     <Header/>
     <br/>
     <br/>
     <br/>
     <div id="content" className="main-content" style={{width: '120px !important'}}>
        <div className="layout-px-spacing">
          <div className="content-container">
            <div className="col-left layout-top-spacing">
              <div className="col-left-content">
                <div className="header-container">
                  <header className="header navbar navbar-expand-sm">                                    
                    <div className="d-flex">
                      <a href="javascript:void(0);" className="sidebarCollapse" data-placement="bottom">
                        <div className="bt-menu-trigger">
                          <span />
                        </div>
                      </a>
                      <br/>
                      <br/>
                      <br/>
                      <div className="page-header">
                        <div className="page-title">
                        <h6 style={{marginLeft: '0px', fontWeight: 'bold'}}>Reports</h6>
                        </div>
                      </div>
                    </div>
                  </header>
                </div>
                <div className="admin-data-content layout-top-spacing mywidth" style={{marginLeft: '30px'}}>
                  <a href="/reports" className="btn btn-primary">Monthly Summary</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="/userlocation" className="btn btn-success">Users Per Location</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="/usermonth" className="btn btn-success">Users Per month</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="/expenses" className="btn btn-success">Expenses</a>
                  <div className="row">
                    <div className="admin-data-content layout-top-spacing">
                      <div className="row layout-top-spacing mywidth" id="cancel-row">
                      <div col-lg-10>
                          <label>Select Month</label>
                            <select className="form-control" value={state.month} onChange={handleChange} id="month" name="month">
                              <option value="">-Select-</option>
                              <option value="1">January</option>
                              <option value="2">February</option>
                              <option value="3">March</option>
                              <option value="4">April</option>
                              <option value="5">May</option>
                              <option value="6">June</option>
                              <option value="7">July</option>
                              <option value="8">August</option>
                              <option value="9">September</option>
                              <option value="10">October</option>
                              <option value="11">November</option>
                              <option value="12">December</option>
                            </select>
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div col-lg-10>
                          <label>Select Year</label>
                            <select className="form-control" value={state.year} onChange={handleChange} id="year" name="year">
                              <option value=""> -Select-</option>
                              <option value="2024">2024</option>
                              <option value="2025">2025</option>
                              <option value="2026">2026</option>
                            </select>
                        </div>
                        
                        &nbsp;&nbsp;&nbsp;
                        <div>
                          <br/>
                          <br/>
                            <button onClick={generatetable} className="btn btn-primary" style={{float: 'right', marginTop: '-10px'}}>Generate Table</button>
                          </div>
                        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                          <br />
                          <br />
                          <br />
                          <h6 style={{marginLeft: '-18px', fontWeight: 'bold'}}>Monthly Summary</h6>
                          <br/>
                          <h6 style={{marginLeft: '-18px' }}>Total Passengers: {totalPassengers}</h6>
                          <h6 style={{marginLeft: '-18px'}}>Total Amount: CAD$ {totalAmount}</h6>
                          <div>
                            {/* <a className="btn btn-primary" style={{float: 'right'}}>Download as excel</a> */}
                          </div>
                          <br />
                          <br />
                          <div className="widget-content widget-content-area br-6">
                            <table id="html5-extension" className="table table-hover non-hover" style={{ width: '100%', marginLeft: '-40px' }}>
                            {cust_info == null ? <Spinner animation="border" /> : ""}
                    <DataTableExtensions {...tableData}>
                                <DataTable className=""
                                  columns={columns}
                                  data={data}
                                  noHeader
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  pagination
                                  exportHeaders= {true}
                                  highlightOnHover
                                />
                              </DataTableExtensions>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Reports;
