import React, {useState,useEffect} from 'react';
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component


function Signin(){

const MySwal = withReactContent(Swal)
const [state , setState] = useState({
  loginemail: ''
})

const loginUrl = "https://cci.starlightsoftware.co.bw/api/forgot";

const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}
var postloginData = {
  "email": state.loginemail,
//   "password": state.loginpassword,
};

let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault()
    if (!state.loginemail){
      MySwal.fire({
        title: <p>Please fill all fields</p>,
        didOpen: () => {
        },
      }).then(() => {
      })
    }else{
      MySwal.fire({
        title: <p>please wait...</p>,
        didOpen: () => {
          MySwal.showLoading()
          axios.post(loginUrl, postloginData, axiosConfig)
        .then((response) => {
          console.log("response received: ", response);
          Swal.close()
            window.location.href="verifyauth";
          })
          .catch((err) => {
            Swal.close()
            console.log("AXIOS ERROR: ", err);
            if (err.response.data.message){
              return MySwal.fire(err.response.data.message)
            } else {
              return MySwal.fire(err.response.message)
            }
          })
        },
      }).then(() => {
      })
    }
  }
    

      return (
     <div>
      <div className="form-container outer">
        <div className="form-form">
          <div className="form-form-wrap">
            <div className="form-container">
              <div className="form-content">
                <h1 className>Reset Password</h1>
                <p>Enter your registered email</p>
                <form className="text-left">
                  <div className="form">
                    <div id="username-field" className="field-wrapper input">
                      <label htmlFor="username">EMAIL</label>
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                      <input type="text" value={state.loginemail} onChange={handleChange} className="form-control" id="loginemail" name="loginemail" placeholder="email" />
                    </div>
                    <div className="d-sm-flex justify-content-between">
                      <div className="field-wrapper">
                        <button onClick={handleLoginSubmit} className="btn btn-block btn-danger" value>Submit</button>
                      </div>
                    </div>
                    <div className="division">
                      <span>Admin users only</span>
                    </div>
                    <div className="social">
                    </div>
                  </div>
                </form>
              </div>                    
            </div>
          </div>
        </div>
      </div>
              </div>
               
        );
 
  };

export default Signin;
