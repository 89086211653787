import React, {useState,useEffect} from 'react';
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component


function Authentication(){

const MySwal = withReactContent(Swal)
const [state , setState] = useState({
    newpassword: '',
    confirmpassword: '',
    current: ''
})
const [passtype, setpasstype] = useState('password');
const token = localStorage.getItem('ccicanadaaccesstoken');
const hideme = (e) => {
  e.preventDefault(); 
  setpasstype('text')
}

// const loginUrl = "http://localhost:9600/api/updateuserpassword";
const loginUrl = "https://cci.starlightsoftware.co.bw/api/updateuserpassword";

const user_id = localStorage.getItem('ccicanadauserid');
// const loginUrl = "https://justgymbackend.starlightsoftware.co.bw/api/login";
const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}
var postloginData = {
  "newpassword": state.new,
  "confirm": state.new,
  "current": state.current,
};

let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };

  const bookingsUrl = "https://cci.starlightsoftware.co.bw/api/fetchRegions";
  // const bookingsUrl = "http://localhost:9600/api/fetchRegions";
  var body = {
        "accesstoken": token,
        };
const [cust_info, setcust] = useState([]);

const getdashboardinfo = async (e) => {
  axios.post(bookingsUrl, body, axiosConfig)
  .then((response) => {
        console.log("response received: ", response);
        if(!response){
        setcust(0);
        }else{
        const sortedArray = response.data.data;
        setcust(sortedArray);
        setcust(sortedArray);
        setcust(sortedArray);
        setcust(sortedArray);
        console.log(sortedArray)
        }
  });
  };


  useEffect(() => {
  getdashboardinfo();
  }, []);


  const handlechangepass= (e) => {

    e.preventDefault();
    if (!state.current ||!state.confirmpassword||!state.newpassword) {
      MySwal.fire({
        title: <p>Please fill all fields</p>,
        didOpen: () => {
        },
      }).then(() => {
      });
    } else if(state.confirmpassword =! state.newpassword) {
      MySwal.fire({
        title: <p>New password and confirm password must match</p>,
        didOpen: () => {
        },
      }).then(() => {
      });
    } else {
      var postDatapass = {
        "newPassword": state.newpassword,
        "userid": user_id,
        "accesstoken": token,
    };
    console.log(postDatapass)
    MySwal.fire({
      didOpen: () => {
        MySwal.showLoading()
        axios.post(loginUrl, postDatapass, axiosConfig)
        .then((response) => {
          console.log("response received: ", response);
          // Swal.close()
          MySwal.fire({
            title: <p>Password updated successfully.</p>,
            didOpen: () => {},
          }).then(() => {
            window.location.href = "home";
          });
            
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
            return MySwal.fire(err.response.data.message)
          })
      },
    }).then(() => {
    })
    }
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault()
    if (!state.loginemail || !state.loginemail){
      MySwal.fire({
        title: <p>Please fill all fields</p>,
        didOpen: () => {
        },
      }).then(() => {
      })
    }else{
      MySwal.fire({
        title: <p>please wait...</p>,
        didOpen: () => {
          MySwal.showLoading()
          axios.post(loginUrl, postloginData, axiosConfig)
        .then((response) => {
          console.log("response received: ", response);
          Swal.close()
            localStorage.setItem("ccicanadaaccesstoken", response.data.data.accessToken);
            localStorage.setItem("ccicanadafirstname", response.data.data.firstname);
            localStorage.setItem("ccicanadauserid", response.data.data.id);
            // localStorage.setItem("justgymfitnessrole", response.data.data.role);
            if(!response.data.data.is_registered){
                window.location.href="authentication";
            }else{
                window.location.href="home";
            }
            
          })
          .catch((err) => {
            Swal.close()
            console.log("AXIOS ERROR: ", err);
            return MySwal.fire(err.response.data.message)
          })
        },
      }).then(() => {
      })
    }
  }
    

      return (
     <div>
      <div className="form-container outer">
        <div className="form-form">
          <div className="form-form-wrap">
            <div className="form-container">
              <div className="form-content">
                <h1 className>Change Password</h1>
                <form className="text-left">
                  <div className="form">
                    <div id="username-field" className="field-wrapper input">
                      <label htmlFor="username">Generated Password</label>
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx={12} cy={7} r={4} /></svg>
                      <input type="text" value={state.current} onChange={handleChange} className="form-control" id="current" name="current" placeholder="email" />
                    </div>

                    <div id="password-field" className="field-wrapper input mb-2">
                      <div className="d-flex justify-content-between">
                        <label htmlFor="password">New Password</label>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-lock"><rect x={3} y={11} width={18} height={11} rx={2} ry={2} /><path d="M7 11V7a5 5 0 0 1 10 0v4" /></svg>
                      <input type={passtype} value={state.newpassword} className="form-control"  onChange={handleChange} id="newpassword" name="newpassword" placeholder="Password" />
                      <svg onClick={hideme} xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" id="toggle-password" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" /><circle cx={12} cy={12} r={3} /></svg>
                    </div>

                    <div id="password-field" className="field-wrapper input mb-2">
                      <div className="d-flex justify-content-between">
                        <label htmlFor="password">Confirm Password</label>
                        {/* <a href="auth_pass_recovery_boxed.html" className="forgot-pass-link">Forgot Password?</a> */}
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-lock"><rect x={3} y={11} width={18} height={11} rx={2} ry={2} /><path d="M7 11V7a5 5 0 0 1 10 0v4" /></svg>
                      <input type={passtype} value={state.confirmpassword} className="form-control"  onChange={handleChange} id="confirmpassword" name="confirmpassword" placeholder="Password" />
                      <svg onClick={hideme} xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" id="toggle-password" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" /><circle cx={12} cy={12} r={3} /></svg>
                    </div>
                    <div className="d-sm-flex justify-content-between">
                      <div className="field-wrapper">
                        <button onClick={handlechangepass} className="btn btn-block btn-danger" value>Log In</button>
                      </div>
                    </div>
                    <div className="division">
                      <span>Admin users only</span>
                    </div>
                    <div className="social">
                    </div>
                    {/* <p className="signup-link">Not registered ? <a href="#">Create an account</a></p> */}
                  </div>
                </form>
              </div>                    
            </div>
          </div>
        </div>
      </div>
              </div>
               
        );
 
  };

export default Authentication;
