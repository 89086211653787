import React, { useState } from 'react';
import Swal from 'sweetalert2';

function Deleterequest() {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form submission logic here (e.g., send request to server)
    Swal.fire({
      title: 'Request Submitted',
      html: 'Your account deletion request has been submitted. It will be reviewed and processed shortly.<br><br>Please also check the settings page in the app for instructions to delete your account personally.',
      icon: 'success',
      confirmButtonText: 'OK'
    });
    setEmail(''); // Clear the form
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Account Deletion Request</h1>
        <p>Please enter your email address to request account deletion. You can also delete your account personally by following the instructions in the app's settings page.</p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button type="submit">Submit Request</button>
        </form>
      </header>
    </div>
  );
}

export default Deleterequest;
