// Header.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';


function Footer() {

  const location = useLocation();

  const isCurrentPage = (path) => {
    // Check if the current route matches the provided path
    return location.pathname === path;
  };


  return (
    <div>
   <div className="footer-wrapper col-xl-12">
        <div className="footer-section f-section-1" style={{marginLeft: '300px'}}>
          <p className>© 2024 <a target="_blank" href="https://designreset.com/">Celebration Church International</a></p>
        </div>
        <div className="footer-section f-section-2">
        </div>
      </div>
  </div>
  );
}

export default Footer;
