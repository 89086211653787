import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from "axios";
import dayjs from "dayjs";
import Spinner from 'react-bootstrap/Spinner';
import withReactContent from 'sweetalert2-react-content'
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";



function Bookings(){
const urlParams = new URLSearchParams(window.location.search);
const [showLoader, setShowLoader] = useState(false);

const myidd = urlParams.get('myidd');
const MySwal = withReactContent(Swal)
const [cust_info, setcust] = useState([]);
// const baseUrl = "http://localhost:9600/api/fetchUserById";
const baseUrl = "https://cci.starlightsoftware.co.bw/api/fetchUserById";
const deactivateurl = "https://cci.starlightsoftware.co.bw/api/deactivateUser"
const activateurl = "https://cci.starlightsoftware.co.bw/api/activateUser"

const token = localStorage.getItem('ccicanadaaccesstoken')

let axiosConfig = {
headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
}
};


var body = {
      "accesstoken": token,
      "bookingId": myidd
      };
     
const getdashboardinfo = async (e) => {
  // e.preventDefault();
      axios.post(baseUrl, body, axiosConfig)
      .then((response) => {
            console.log("response received: ", response);
            if(!response){
            setcust(0);
            }else{
            const sortedArray = response.data.data[0];
            setcust(sortedArray);
            setcust(sortedArray);
            console.log(sortedArray)
            }
      });
      };


      useEffect(() => {
      getdashboardinfo();
      // getitemsinfo();
      }, []);

  const deactivate = (e) => {
        e.preventDefault();
          var postDatas = {
            "userId": cust_info.id,
            "accesstoken": token,
        };
      console.log(postDatas)
        MySwal.fire({
          didOpen: () => {
            MySwal.showLoading()
            axios.post(deactivateurl, postDatas, axiosConfig)
            .then((response) => {
              console.log("response received: ", response);
              // Swal.close()
              MySwal.fire({
                title: <p>User updated successfully</p>,
                didOpen: () => {},
              }).then(() => {
                window.location.reload(false);
              });
                
              })
              .catch((err) => {
                Swal.close()
                console.log("AXIOS ERROR: ", err);
                return MySwal.fire(err.response.data.message)
              })
          },
        }).then(() => {
          // Swal.close()
        })
        // }
      };


    const activate = async (e) => {
      e.preventDefault();
      var postDatas = {
        "userId": cust_info.id,
        "accesstoken": token,
      };
      console.log(postDatas)
        MySwal.fire({
        didOpen: () => {
          MySwal.showLoading()
          axios.post(activateurl, postDatas, axiosConfig)
          .then((response) => {
            console.log("response received: ", response);
            // Swal.close()
            MySwal.fire({
              title: <p>User updated successfully</p>,
              didOpen: () => {},
            }).then(() => {
              window.location.reload(false);
            }); 
            })
            .catch((err) => {
              Swal.close()
              console.log("AXIOS ERROR: ", err);
              return MySwal.fire(err.response.data.message)
            })
        },
        }).then(() => {
        })
      }


      return (
     <div>
     <Header/>
     <br/>
     <br/>
     <br/>

     <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="content-container">
            <div className="col-left layout-top-spacing">
              <div className="col-left-content">
                <div className="header-container">
                  <header className="header navbar navbar-expand-sm">                                    
                    <div className="d-flex">
                      <a href="javascript:void(0);" className="sidebarCollapse" data-placement="bottom">
                        <div className="bt-menu-trigger">
                          <span />
                        </div>
                      </a>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <div className="page-header">
                        <div className="page-title">
                        </div>
                      </div>
                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="/users" className="btn btn-danger">Go Back</a>
                   
                  </header>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12 layout-top-spacing card">
                  <div className="user-profile layout-spacing p-10" style={{padding: '10px'}}>
                    <div className="widget-content widget-content-area" style={{padding: '10px'}}>
                      <div className="d-flex justify-content-between">
                        <h3 className>User Information</h3>
                        {/* <a href="#" className="mt-2 edit-profile"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-3"><path d="M12 20h9" /><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" /></svg></a> */}
                      </div>
                      <div className="text-center user-info">
                      </div>
                      <div className="user-info-list">
                        <div className>
                          <ul className="contacts-block list-unstyled">
                          <br/>
                          <br/>
                          
                            <br/>
                            <li className="contacts-block__item">
                              {/* Name: <b style={{fontWeight: 700}}>{cust_info.created_at}</b> */}
                              REG DATE: <b style={{fontWeight: 700}}>{dayjs(cust_info.created_at).format("DD MMM YY")}</b>
                            </li>
                            <br/>
                            <li className="contacts-block__item">
                              {/* Name: <b style={{fontWeight: 700}}>{cust_info.created_at}</b> */}
                              Name: <b style={{fontWeight: 700}}>{cust_info.firstname}</b>
                            </li>
                            <br />
                            <li className="contacts-block__item">
                              Address: <b style={{fontWeight: 700}}>{cust_info.address}</b>
                              {/* Address: <b style={{fontWeight: 700}}>{cust_info.bookingregion}</b> */}
                            </li>
                            <br />
                            <li className="contacts-block__item">
                              {/* Region: <b>{cust_info.price}</b> */}
                              Region: <b> {cust_info.region}</b>
                            </li>
                            <br />
                            <li className="contacts-block__item">
                              {/* Phone: <b>{cust_info.bookingdate}</b> */}
                              Phone: <b>{cust_info.phone}</b>
                            </li>
                            <br />
                            <li className="contacts-block__item">
                              {/* Email: <b>{cust_info.trip}</b> */}
                              Email: <b>{cust_info.email}</b>
                            </li>
                            <br />
                           
                          </ul>
                        </div>                                    
                      </div>
                      {cust_info.is_active==1 ? (
                          <>
                            <button onClick={deactivate} className="btn btn-danger">Deactivate Account</button>
                          </>
                        ) : (
                          <>
                            <button onClick={activate} className="btn btn-success">Activate Account</button>
                          </>
                        )}
                      <button class="btn btn-warning" style={{ marginLeft: '8px' }}>Reset Password</button>
                    </div>
                  </div>
                </div>
              </div></div></div></div></div>
       
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Bookings;
