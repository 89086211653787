import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";


function Settings(){
  const MySwal = withReactContent(Swal)
  let history = useHistory();


  const baseUrl = "https://cci.starlightsoftware.co.bw/api/newregion";
  const regionUrl = "https://cci.starlightsoftware.co.bw/api/updatepassword";
  const updateregion = "https://cci.starlightsoftware.co.bw/api/updateRegions";
  const fetchaddressurl = "https://cci.starlightsoftware.co.bw/api/fetchAddress";
  const addressUrl = "https://cci.starlightsoftware.co.bw/api/createaddress";
  const pickupUrl = "https://cci.starlightsoftware.co.bw/api/createpickup";
  const token = localStorage.getItem('ccicanadaaccesstoken');
  const myid = localStorage.getItem('ccicanadauserid');
  const fname = localStorage.getItem('ccing_fname');
  const [latitudeinfo, setLatitude] = useState(localStorage.getItem('ccing_weblatitude'));
  const [longitudeinfo, setLongitude] = useState(localStorage.getItem('ccing_weblongitude'));
  const [addressinfo, setAddress] = useState('');
  const [pickupinfo, setpickupAddress] = useState('');

  

  const [state , setState] = useState({
    "current": '',
    "new": '',
    "rateid": '',
    "confirm": '',
    "rate": '',
    "newregion": '',
    "newrate": '',
    "pickupname": '',
    "pickupaddress": '',
})


  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };

const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}

const logout = (e) => {
window.location.href = '/'
localStorage.removeItem('ccicanadaaccesstoken');
localStorage.removeItem('ccicanadaaccesstoken');

}


const {
  ready,
  value,
  suggestions: { status, data },
  setValue,
  clearSuggestions,
} = usePlacesAutocomplete({
  callbackName: "initMap",
  requestOptions: {
    /* Define search scope here */
  },
  debounce: 300,
});

const ref = useOnclickOutside(() => {
  clearSuggestions();
});

const handleSelect =
  ({ description }) =>
  () => {
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      console.log("📍 Coordinates: ", { lat, lng });
      console.log(description)
      setLatitude(lat);
      setLatitude(lat);
      setLatitude(lat);
      setLongitude(lng);
      setLongitude(lng);
      setLongitude(lng);
      setAddress(description)
      setAddress(description)
      setAddress(description)
    });
  };


  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });




const handleInput = (e) => {
  setValue(e.target.value);
};

const handlechangepass= (e) => {
  e.preventDefault();
  if (!state.current ||!state.confirm||!state.new) {
    MySwal.fire({
      text: <p>Please fill all fields</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  } else if(state.confirm =! state.new) {
    MySwal.fire({
      text: <p>New password and confirm password must match</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  } else {
    var postDatapass = {
      "newPassword": state.new,
      "userid": myid,
      "accesstoken": token,
  };
  console.log(postDatapass)
  MySwal.fire({
    didOpen: () => {
      MySwal.showLoading()
      axios.post(regionUrl, postDatapass, axiosConfig)
      .then((response) => {
        console.log("response received: ", response);
        // Swal.close()
        MySwal.fire({
          text: <p>Region Created successfully</p>,
          didOpen: () => {},
        }).then(() => {
          window.location.href = "settings";
        });
          
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
          return MySwal.fire(err.response.data.message)
        })
    },
  }).then(() => {
  })
  }
};

const handlenewregion = (e) => {
  e.preventDefault();
  if (!state.newregion ||!state.rate) {
    MySwal.fire({
      title: <p>Please fill all fields</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  } else {
    var postDatas = {
      "region_name": state.newregion,
      "price": state.rate,
      "accesstoken": token,
  };
  console.log(postDatas)

  MySwal.fire({
    didOpen: () => {
      MySwal.showLoading()
      axios.post(baseUrl, postDatas, axiosConfig)
      .then((response) => {
        console.log("response received: ", response);
        // Swal.close()
        MySwal.fire({
          text: <p>Region Created successfully</p>,
          didOpen: () => {},
        }).then(() => {
          window.location.href = "settings";
        });
          
        })
        .catch((err) => {
          // Swal.close()
          console.log("AXIOS ERROR: ", err);
          return MySwal.fire(err.response.data.message)
        })
    },
  }).then(() => {
    // Swal.close()
  })
  }
};

const [cust_info, setcust] = useState([]);
const [regionrate, setregionrate] = useState('');
const [selectedPrice, setSelectedPrice] = useState('');


const [selectedRegion, setSelectedRegion] = useState('');
const [selectedID, setSelectedID] = useState('');

const handleRegionChange = (event) => {
  const selectedRegion = event.target.value;
  setSelectedRegion(selectedRegion);
  const selectedCustInfo = cust_info.find(info => info.region_name === selectedRegion);
  const selectedCustId = cust_info.find(info => info.id === selectedCustInfo.id);
  setSelectedID(selectedCustId.id);
  if (selectedCustInfo) {
    setSelectedPrice(selectedCustInfo.price);
    state.newrate = selectedCustInfo.price;
    state.rateid = selectedCustInfo.id;
  }
};




const updateRate = (e) => {
  e.preventDefault();
  if (!state.newrate) {
    MySwal.fire({
      title: <p>Please select region and edit rates</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  } else {
    var postData = {
      "price": state.newrate,
      "id": state.rateid,
  };
  console.log(postData)

  MySwal.fire({
    didOpen: () => {
      MySwal.showLoading()
      axios.post(updateregion, postData, axiosConfig)
      .then((response) => {
        console.log("response received: ", response);
        MySwal.fire({
          title: <p>Region Updated successfully</p>,
          didOpen: () => {},
        }).then(() => {
          window.location.reload(false)
        });
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
          return MySwal.fire(err.response.data.message)
        })
    },
  }).then(() => {
    // Swal.close()
  })
  }
};



const handlenewAddress = (e) => {
  e.preventDefault();
  if (!addressinfo) {
    MySwal.fire({
      title: <p>Please enter new Address</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  } else {
    var postaddressData = {
      "churchaddress": addressinfo,
      "accesstoken": token,
      "latitude": latitudeinfo,
      "longitude": longitudeinfo,
  };
  console.log(postaddressData)

  MySwal.fire({
    didOpen: () => {
      MySwal.showLoading()
      axios.post(addressUrl, postaddressData, axiosConfig)
      .then((response) => {
        console.log("response received: ", response);
        localStorage.setItem('ccing_weblatitude', latitudeinfo);
        localStorage.setItem('ccing_weblongitude', longitudeinfo);
        localStorage.setItem('ccing_webaddress', addressinfo);
        MySwal.fire({
          title: <p>Address Updated successfully</p>,
          didOpen: () => {},
        }).then(() => {
          window.location.reload(false)
        });
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
          return MySwal.fire(err.response.data.message)
        })
    },
  }).then(() => {
    // Swal.close()
  })
  }
};



const createPickup = (e) => {
  e.preventDefault();

  if (!state.pickupaddress||!state.pickupname||!selectedRegion) {
    MySwal.fire({
      title: <p>Please fill all fields</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  } else {
    var postpickupData = {
      "region_id": selectedID,
      "region_name": selectedRegion,
      "pickupname": state.pickupname,
      "pickupaddress": state.pickupaddress,
      "created_by": fname,
      "accesstoken": token
  };

  console.log(postpickupData)

  MySwal.fire({
    didOpen: () => {
      MySwal.showLoading()
      axios.post(pickupUrl, postpickupData, axiosConfig)
      .then((response) => {
        console.log("response received: ", response);
        MySwal.fire({
          // title: <p>Pickup Location Created successfully</p>,
          text: "Pickup Location Created successfully",
          didOpen: () => {},
        }).then(() => {
          window.location.reload(false)
        });
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
          return MySwal.fire(err.response.data.message)
        })
    },
  }).then(() => {
    // Swal.close()
  })
  }
};




const bookingsUrl = "https://cci.starlightsoftware.co.bw/api/fetchRegions";

var body = {
      "accesstoken": token,
      "id": 1
      };
     
const getdashboardinfo = async (e) => {
      axios.post(bookingsUrl, body, axiosConfig)
      .then((response) => {
            console.log("response received: ", response);
            if(!response){
            setcust(0);
            }else{
            const sortedArray = response.data.data;
            setcust(sortedArray);
            setcust(sortedArray);
            console.log(cust_info)
            }
      });
      };

      const getaddressinfo = async (e) => {
        axios.post(fetchaddressurl, body, axiosConfig)
        .then((response) => {
              console.log("response received: ", response);
              if(!response){
              setAddress(0);
              }else{
              const msortedArray = response.data.data[0].address;
              setAddress(msortedArray);
              setAddress(msortedArray);
              console.log(msortedArray)
              }
        });
        };


      useEffect(() => {
      getdashboardinfo();
      getaddressinfo();
      }, []);

    

      return (
     <div>
     <Header/>
     <br/>
     <br/>
     <br/>
     <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="content-container">
            <div className="col-left layout-top-spacing">
              <div className="col-left-content">
                <div className="header-container">
                  <header className="header navbar navbar-expand-sm">                                    
                    <div className="d-flex">
                      <a href="javascript:void(0);" className="sidebarCollapse" data-placement="bottom">
                        <div className="bt-menu-trigger">
                          <span />
                        </div>
                      </a>
                      <br/><br/><br/><br/><br/><br/>
                      <div className="page-header">
                        <div className="page-title">
                        <h6 style={{marginLeft: '-18px', fontWeight: 'bold'}}>Settings</h6> 
                        </div>
                      </div>
                      
                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button onClick={logout} className="btn btn-danger" style={{marginTop: '10px'}}>Logout</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="/reports" style={{marginTop: '10px'}} className="btn btn-primary">Reports</a>
                  </header>
                </div>
                <br />
                <br />
                <div className="admin-data-content layout-top-spacing">
                  <div className="account-settings-container layout-top-spacing">
                    <div className="account-content">
                      <div className="scrollspy-example" data-spy="scroll" data-target="#account-settings-scroll" data-offset={-100}>
                        <div className="row card" style={{padding: '10px'}}>
                          <div className="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <form id="general-info" className="section general-info">
                              <div className="info">
                                <h6 className>Change Password</h6>
                                <div className="row">
                                  <div className="col-lg-11 mx-auto">
                                    <div className="row">
                                      <div className="col-xl-10 col-lg-12 col-md-8 mt-md-0 mt-4">
                                        <br />
                                        <br />
                                        <br />
                                        <div className="form">
                                          <div className="row">
                                            <div className="col-sm-6">
                                              <div className="form-group">
                                                <label htmlFor="fullName">Current Password</label>
                                                 <input type="password" placeholder="New Password" value={state.current} onChange={handleChange} id="current" name="current" className="form-control bg-transparent" />
                                              </div>
                                            </div>
                                            <div className="col-sm-6">
                                              <div className="form-group">
                                                <label htmlFor="fullName">New Password</label>
                                                <input type="password" placeholder="New Password" value={state.new} onChange={handleChange} id="new" name="new" className="form-control bg-transparent" />
                                              </div>
                                            </div>
                                            <div className="col-sm-6">
                                              <div className="form-group">
                                                <label htmlFor="fullName">Confirm New Password</label>
                                                <input type="password" placeholder="New Password" value={state.confirm} onChange={handleChange} id="confirm" name="confirm" className="form-control bg-transparent" />
                                              </div>
                                            </div>
                                          </div>
                                          <div>
                                            <button onClick={handlechangepass} className="btn btn-success"> Change Password</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <br />
                      <div className="scrollspy-example" data-spy="scroll" data-target="#account-settings-scroll" data-offset={-100}>
                        <div className="row card" style={{padding: '10px'}}>
                          <div className="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <form id="general-info" className="section general-info">
                            <a href="/viewregions" style={{float: 'right'}} className='btn btn-primary'>View all Regions </a>
                            <br/>
                            <br/>
                            <br/>
                              <div className="info">
                                <h6 className>Update Rates Per Region</h6>
                                <div className="row">
                                  <div className="col-lg-11 mx-auto">
                                    <div className="row">
                                      <div className="col-xl-10 col-lg-12 col-md-8 mt-md-0 mt-4">
                                        <br />
                                        <br />
                                        <br />
                                        <div className="form">
                                        <div className="row">
                                        <div className="col-6">
                                          <label>Select Region</label>
                                          <select className="form-control basic" value={selectedRegion} onChange={handleRegionChange}>
                                            <option>--Select--</option>
                                            {cust_info.map(info => (
                                              <option key={info.id} value={info.region_name}>
                                                {info.region_name}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        <div className="col-sm-6">
                                          <div className="form-group">
                                            <label htmlFor="fullName">Update Rate(CAD$)</label>
                                           <input type="text" placeholder="New Rate" value={state.newrate} onChange={handleChange} id="newrate" name="newrate" className="form-control bg-transparent" />

                                          </div>
                                        </div>
                                      </div>
                                          <div>
                                            <button onClick={updateRate} className="btn btn-success"> Update Rate</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                            {/* <button className="btn btn-danger"> Delete Selected</button> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <br />
                      <div className="scrollspy-example" data-spy="scroll" data-target="#account-settings-scroll" data-offset={-100}>
                        <div className="row card" style={{padding: '10px'}}>
                          <div className="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <form id="general-info" className="section general-info">
                            <a href="/viewpickupregions" style={{float: 'right'}} className='btn btn-primary'>View all Pickup Addresses </a>
                            <br/>
                            <br/>
                            <br/>
                              <div className="info">
                                <h6 className>Create Pickup Address</h6>
                                <div className="row">
                                  <div className="col-lg-11 mx-auto">
                                    <div className="row">
                                      <div className="col-xl-10 col-lg-12 col-md-8 mt-md-0 mt-4">
                                        <br />
                                        <br />
                                        <br />
                                        <div className="form">
                                        <div className="row">
                                        <div className="col-6">
                                          <label>Select Region</label>
                                          <select className="form-control basic" value={selectedRegion} onChange={handleRegionChange}>
                                            <option>--Select--</option>
                                            {cust_info.map(info => (
                                              <option key={info.id} value={info.region_name}>
                                                {info.region_name}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        <div className="col-sm-6">
                                          <div className="form-group">
                                            <label htmlFor="fullName">Pickup Name</label>
                                           <input type="text" placeholder="New Name" value={state.pickupname} onChange={handleChange} id="pickupname" name="pickupname" className="form-control bg-transparent" />
                                          </div>
                                        </div>
                                            <div className="col-sm-12">
                                              <div className="form-group">
                                                <label htmlFor="fullName">Enter Pickup Address</label>
                                               <input type="text" placeholder="Enter Pickup Address" value={state.pickupaddress} onChange={handleChange} id="pickupaddress" name="pickupaddress" className="form-control bg-transparent" />

                                              </div>
                                            </div>
                                      </div>
                                          <div>
                                            <button onClick={createPickup} className="btn btn-success"> Submit</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                            {/* <button className="btn btn-danger"> Delete Selected</button> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <br />
                      <div className="scrollspy-example" data-spy="scroll" data-target="#account-settings-scroll" data-offset={-100}>
                        <div className="row card" style={{padding: '10px'}}>
                          <div className="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <form id="general-info" className="section general-info">
                              <div className="info">
                                <h6 className>Create New Region</h6>
                                <div className="row">
                                  <div className="col-lg-11 mx-auto">
                                    <div className="row">
                                      <div className="col-xl-10 col-lg-12 col-md-8 mt-md-0 mt-4">
                                        <br />
                                        <br />
                                        <br />
                                        <div className="form">
                                          <div className="row">
                                            <div className="col-sm-6">
                                              <div className="form-group">
                                                <label htmlFor="fullName">Enter Region</label>
                                                <input type="text" placeholder="New Region" value={state.newregion} onChange={handleChange} id="newregion" name="newregion" className="form-control bg-transparent" />
                                              </div>
                                            </div>
                                            <div className="col-sm-6">
                                              <div className="form-group">
                                                <label htmlFor="fullName">Region Rate</label>
                                                <input type="text" placeholder="New Rate" value={state.rate} onChange={handleChange} id="rate" name="rate" className="form-control bg-transparent" />
                                              </div>
                                            </div>
                                          </div>
                                          <div>
                                            <button onClick={handlenewregion} className="btn btn-success"> Add  Region</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <br/>
                      <br/>
                      <div className="scrollspy-example" data-spy="scroll" data-target="#account-settings-scroll" data-offset={-100}>
                        <div className="row card" style={{padding: '10px'}}>
                          <div className="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                            <form id="general-info" className="section general-info">
                              <div className="info">
                                <h6 className>Update Church Address</h6>
                                
                                <div className="row">
                                  <div className="col-lg-11 mx-auto">
                                    <div className="row">
                                      <div className="col-xl-10 col-lg-12 col-md-8 mt-md-0 mt-4">
                                        <br />
                                        <br />
                                        <p>Current Address: <b>{addressinfo}</b></p>
                                        <br />
                                        <div className="form">
                                          <div className="row">
                                            <div className="col-sm-12">
                                              <div className="form-group">
                                                <label htmlFor="fullName">Enter New Address</label>
                                                <input
                                                  value={value}
                                                  onChange={handleInput}
                                                  className='form-control'
                                                  disabled={!ready}
                                                  placeholder="Enter Complete Address"
                                                />
                                                {status === "OK" && <ul className='card'>{renderSuggestions()}</ul>}
                                              </div>
                                            </div>
                                           
                                          </div>
                                          <div>
                                            <button onClick={handlenewAddress} className="btn btn-success"> Update Address</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div></div></div></div></div></div></div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Settings;
