import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component


function Schedule(){

const MySwal = withReactContent(Swal)
  const first_name = localStorage.getItem('sl_med_fname');
  const [showLoader, setShowLoader] = useState(false);
  const [showschedule, setscheduler] = useState([]);
  const [showschedule2, setscheduler2] = useState([]);
  const [showaddress, setaddress] = useState([]);
const token = localStorage.getItem('ccicanadaaccesstoken');

  const [state , setState] = useState({
    date: '',
    seats: '',
    timeslot: '',
    region: '',
})

const fetchaddressurl = "https://cci.starlightsoftware.co.bw/api/fetchAddress";
const approveschedule = "https://cci.starlightsoftware.co.bw/api/approveschedule"


var addressbody = {
  "accesstoken": token,
  "id": 1,
  };

const getAddress = async (e) => {
  axios.post(fetchaddressurl, addressbody, axiosConfig)
  .then((response) => {
        // console.log("response received: ", response);
        if(!response){
        setcust(0);
        }else{
        const sortedArray = response.data.data[0];
        setaddress(sortedArray);
        setaddress(sortedArray);
        setaddress(sortedArray);
        // console.log(sortedArray)
        }
  });
  };

function printDiv(divId) {
  const printContents = document.getElementById(divId).innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  window.location.reload(false)
}


const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
} 



let axiosConfig = {
  headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
  }
};

const bookingsUrl = "https://cci.starlightsoftware.co.bw/api/fetchRegions";
// const scheduleUrl = "http://localhost:9650/api/schedule";
const scheduleUrl = "https://cci.starlightsoftware.co.bw/api/schedule";
var body = {
      "accesstoken": token,
      };
const [cust_info, setcust] = useState([]);

const getdashboardinfo = async (e) => {
axios.post(bookingsUrl, body, axiosConfig)
.then((response) => {
      console.log("response received: ", response);
      if(!response){
      setcust(0);
      }else{
      const sortedArray = response.data.data;
      setcust(sortedArray);
      setcust(sortedArray);
      setcust(sortedArray);
      setcust(sortedArray);
      // console.log(sortedArray)
      }
});
};


useEffect(() => {
getdashboardinfo();
getAddress();
}, []);

// 

var postData = {
  "bookingDate": state.date,
  "timeslot": state.timeslot,
  "number_of_passengers": state.seats,
  "region": state.region,
  "defaultlat": showaddress.latitude,
  "defaultlong": showaddress.longitude,
};

var postscheduleData = {
  "arrayOfArrays": showschedule2,
  "bookingregion": state.region,
  "bookingtimeslot": state.timeslot,
  "thebookingdate": state.date
};



const approvenewschedule = (e) => {
  e.preventDefault();
  console.log('op');

  if (!showschedule) {
    MySwal.fire({
      title: <p>Please create a schedule</p>,
      didOpen: () => {},
    }).then(() => {});
  } else {
    console.log(postscheduleData);
    MySwal.fire({
      title: <p>please wait...</p>,
      didOpen: () => {
        MySwal.showLoading()
        console.log(postData)
        axios.post(approveschedule, postscheduleData, axiosConfig)
      .then((response) => {
        console.log("response received: ", response);
        console.log(response.data)
          Swal.fire({
              title: 'Successful',
              text: "Schedule updated successfully",
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "View",
              closeOnCancel: true,
            }).then(result => {
              setShowLoader(true);
              console.log(response.data)
              window.location.href="viewschedules";
            })
        })
        .catch((err) => {
        console.log(postData)
          Swal.close()
          console.log("AXIOS ERROR: ", err);
          return MySwal.fire(err.response.data.message)
        })
      },
    }).then(() => {
    })
  }
};

const handlenewSchedule = (e) => {
    e.preventDefault();
    console.log('op');
  
    if (!state.date || !state.seats || !state.timeslot || !state.region) {
      MySwal.fire({
        title: <p>Please fill all fields</p>,
        didOpen: () => {},
      }).then(() => {});
    } else {
      console.log(postData);
      MySwal.fire({
        title: <p>please wait...</p>,
        didOpen: () => {
          MySwal.showLoading()
          console.log(postData)
          axios.post(scheduleUrl, postData, axiosConfig)
        .then((response) => {
          console.log("response received: ", response);
          console.log(response.data)
            Swal.fire({
                title: 'Users Retrieved Succesfully',
                // text: response.data.message,
                icon: "success",
                showCancelButton: false,
                confirmButtonText: "View",
                closeOnCancel: true,
              }).then(result => {
                setShowLoader(true);
                setscheduler(response.data)
                setscheduler(response.data)
                setscheduler(response.data)
                setscheduler(response.data)
                setscheduler2(response.data);
                setscheduler2(response.data);
                setscheduler2(response.data);
                console.log(response.data)
                // window.location.href="bookings";
              })
          })
          .catch((err) => {
          console.log(postData)
            Swal.close()
            console.log("AXIOS ERROR: ", err);
            window.location.reload(false);
            return MySwal.fire(err.response.data.message)
          })
        },
      }).then(() => {
      })
    }
  };
  

      return (
     <div>
     <Header/>
     <br/>
     <br/>
     <br/>
     <div id="content" className="main-content" style={{width: '120px !important'}}>
        <div className="layout-px-spacing">
          <div className="content-container">
            <div className="col-left layout-top-spacing">
              <div className="col-left-content">
                <div className="header-container">
                  <header className="header navbar navbar-expand-sm">   
                    <div className="d-flex">
                      <a href="javascript:void(0);" className="sidebarCollapse" data-placement="bottom">
                        <div className="bt-menu-trigger">
                          <span />
                        </div>
                      </a>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <div className="page-header">
                        <div className="page-title">
                        <a href="/bookings" className="btn btn-danger"> Go Back</a>                             
                        </div>  &nbsp;&nbsp;&nbsp;  
                        <div className="page-title">
                        <a href="/viewschedules" className="btn btn-primary" >Schedules</a>                                 
                        </div>
                      </div>
                    </div>
                  </header>
                </div>
                <div className="admin-data-content mywidth ">
                <div className="page-header" style={{marginLeft: '50px'}}>
                        <div className="page-title">
                        <h6 style={{marginLeft: '-18px', fontWeight: 'bold'}}>Start Schedule</h6>
                        </div>
                      </div>
                  <div className="row" style={{marginLeft:'-200px'}}>
                    <div className="admin-data-content layout-top-spacing">
                    <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="fullName">Select Date</label>
                        <input type="date" value={state.date} onChange={handleChange} id="date" name="date" className="form-control mb-4" placeholder="Date" />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="fullName">Select Timeslot</label>
                        <select className="form-control" value={state.timeslot} onChange={handleChange} id="timeslot" name="timeslot">
                          <option>--select--</option>
                          <option value='8:00am'>8:00am</option>
                          <option value='9:00am'>9:00am</option>
                          <option value='10:00am'>10:00am</option>
                          <option value='11:00am'>11:00am</option>
                          <option value='1:00pm'>1:00pm</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="fullName">Select Region</label>
                        <select className="form-control" value={state.region} onChange={handleChange} id="region" name="region">
                        <option>--select--</option>
                                            {cust_info.map(info => (
                                              <option key={info.id} value={info.region_name}>
                                                {info.region_name}
                                              </option>
                                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="fullName">Enter Number of seats per ride</label>
                        <input type="number" className="form-control mb-4" value={state.seats} onChange={handleChange} id="seats" name="seats" placeholder="seats" />
                      </div>
                    </div>
                  </div>
                        <button onClick={handlenewSchedule} className='btn btn-success'>Start Schedule</button>
                        </div>
                        </div>
                       
            {showLoader? 
             <div id='printable-content'>
             <br/>
             <br/>
             <br/>
             <br/>
             {showschedule.length === 0 ? (
               <p>There are no pending bookings for your search parameters</p>
             ) : (
               showschedule.map((schedule, scheduleIndex) => (
                 <div key={scheduleIndex} className="col-xl-12 col-lg-12 col-sm-12" style={{ marginTop: '-20px' }}>
                   <h2>Schedule {scheduleIndex + 1}</h2>
                   <div className="widget-content widget-content-area br-6">
                     <table id={`html5-extension-${scheduleIndex}`} className="table table-hover non-hover" style={{ width: '100%' }}>
                       <thead>
                         <tr>
                           <th>User</th>
                           <th>Address</th>
                           <th>Region</th>
                           {/* <th>Distance</th> */}
                           {/* <th>Guests</th> */}
                         </tr>
                       </thead>
                       <tbody>
                         {schedule.map((item, itemIndex) => (
                           <tr key={itemIndex}>
                             <td>{item.firstname}</td>
                             <td>{item.bookingaddress}</td>
                             <td>{item.region}</td>
                             {/* <td>{item.guests === 0 ? 'No Guests' : `Plus ${item.guests} `}</td> */}
                           </tr>
                         ))}
                       </tbody>
                     </table>
                   </div>
                 </div>
               ))
             )}
             {showschedule.length !== 0 && (
               <div className="button-group">
                 <a target='_blank' onClick={() => printDiv('printable-content')} className='btn btn-primary'>Print Schedule</a>&nbsp;&nbsp;
                 <button onClick={approvenewschedule} className='btn btn-success'>Approve Schedule</button>
               </div>
             )}
           </div>
           
              :
              <p>No booking data</p>
        } 

        <br/>
        <br/>
        <br/>

                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="footer-wrapper col-xl-12">
                  <div className="footer-section f-section-1">
                    <p className>© 2023 <a target="_blank" href="https://designreset.com/">Celebration Church International</a></p>
                  </div>
                  <div className="footer-section f-section-2">
                  </div>
                </div> */}
              </div>
            {/* </div> */}
          {/* </div>
        </div> */}
      </div>
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Schedule;
