import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import axios from "axios";
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import Header from './header'; 
import Footer from './footer';


function Contact(){

  const MySwal = withReactContent(Swal)
  let history = useHistory();
  // const [cust_info, setcust] = useState([]);

  // const baseUrl = "https://remitaid.starlightsoftware.co.bw/api/login";
  const baseUrl = "https://cci.starlightsoftware.co.bw/api/registeradmin";
  // const baseUrl = "http://localhost:9650/api/registeradmin";
  const token = localStorage.getItem('ccicanadaaccesstoken');

  const [state , setState] = useState({
    "firstname": '',
    "lastname": '',
    "email": '',
    "phonenumber": '',
    "region": '',
    "role": '',
    "password": '',
})

const bookingsUrl = "https://cci.starlightsoftware.co.bw/api/fetchRegions";
// const bookingsUrl = "http://localhost:9600/api/fetchRegions";
var body = {
      "accesstoken": token,
      };
const [cust_info, setcust] = useState([]);

const getdashboardinfo = async (e) => {
axios.post(bookingsUrl, body, axiosConfig)
.then((response) => {
      console.log("response received: ", response);
      if(!response){
      setcust(0);
      }else{
      const sortedArray = response.data.data;
      setcust(sortedArray);
      setcust(sortedArray);
      setcust(sortedArray);
      setcust(sortedArray);
      console.log(sortedArray)
      }
});
};


useEffect(() => {
getdashboardinfo();
}, []);


const generateRandomCode = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let code = '';

  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters.charAt(randomIndex);
  }

  return code;
};

  const [randomCode, setRandomCode] = useState('');

  const handleGenerateCode = () => {
    const newRandomCode = generateRandomCode();
    setRandomCode(newRandomCode);
  };


  

  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };


const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}

const handleLoginSubmit = (e) => {
  e.preventDefault();
  const newRandomCode = generateRandomCode();
  console.log(newRandomCode)

  if (!state.firstname||!state.email ||!state.phonenumber ||!state.region ||!state.role) {
    MySwal.fire({
      title: <p>Please fill all fields</p>,
      didOpen: () => {
      },
    }).then(() => {
    });
  } else {
    var postData = {
      "firstname": state.firstname,
      "lastname": state.firstname,
      "email": state.email,
      "phonenumber": state.phonenumber,
      "region": state.region,
      "role": state.role,
      "password": newRandomCode,
  };
  console.log(postData)

  MySwal.fire({
    didOpen: () => {
      MySwal.showLoading()
      axios.post(baseUrl, postData, axiosConfig)
      .then((response) => {
        console.log("response received: ", response);
        // Swal.close()
        MySwal.fire({
          title: <p>User Created successfully</p>,
          didOpen: () => {},
        }).then(() => {
          window.location.href = "adduser";
        });
          
        })
        .catch((err) => {
          // Swal.close()
          console.log("AXIOS ERROR: ", err);
          return MySwal.fire(err.response.data.message)
        })
    },
  }).then(() => {
    // Swal.close()
  })
  }
};



      return (
     <div>
     <Header/>
     <div className="admin-data-content layout-top-spacing">
        <div className="account-settings-container layout-top-spacing">
          <div className="account-content">
            <div className="scrollspy-example" data-spy="scroll" data-target="#account-settings-scroll" data-offset={-100}>
              <div className="card" style={{padding: '5%', marginLeft: '250px'}}>
                <div className="form">
                  <a href="/users" className="btn btn-danger"> Go Back</a>
                  <br />
                  <br />
                  <h6 style={{marginLeft: '0px', fontWeight: 'bold'}}>Add New User</h6> 
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="fullName">Full Name</label>
                        <input type="text" className="form-control mb-4" value={state.firstname} onChange={handleChange} id="firstname" name="firstname" placeholder="Full Name" />
                      </div>
                    </div>
                    {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="fullName">Last Name</label>
                        <input type="text" className="form-control mb-4" value={state.lastname} onChange={handleChange} id="lastname" name="lastname" placeholder="Last Name" />
                      </div>
                    </div> */}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="fullName">Email</label>
                        <input type="text" className="form-control mb-4" value={state.email} onChange={handleChange} id="email" name="email" placeholder="Email" />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="fullName">Phone</label>
                        <input type="text" className="form-control mb-4" value={state.phonenumber} onChange={handleChange} id="phonenumber" name="phonenumber" placeholder="Phone" />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="fullName">Select Region</label>
                        <select className="form-control" value={state.region} onChange={handleChange} id="region" name="region">
                        <option>--select--</option>
                                            {cust_info.map(info => (
                                              <option key={info.id} value={info.region_name}>
                                                {info.region_name}
                                              </option>
                                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="fullName">Select Role</label>
                        <select className="form-control" value={state.role} onChange={handleChange} id="role" name="role">
                          <option value={''}>--select--</option>
                          <option value={'SubAdmin'}>Sub Admin</option>
                          <option value={'user'}>Mobile app User</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button onClick={handleLoginSubmit} className="btn btn-success"> Create User</button>
                  </div>
                </div>
              </div>
            </div>
          </div></div></div>
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Contact;
