import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from "axios";
import dayjs from "dayjs";
import Spinner from 'react-bootstrap/Spinner';
import withReactContent from 'sweetalert2-react-content'
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";


function Userlocation() {
  const MySwal = withReactContent(Swal)
  const [cust_info, setcust] = useState([]);
  const [regions, setRegions] = useState([])
  const baseUrl = "https://cci.starlightsoftware.co.bw/api/fetchUsersPerRegionPerWeek";
  // const baseUrl = "https://cci.starlightsoftware.co.bw/api/fetchUsersPerRegionPerWeek";
  const regionUrl = "https://cci.starlightsoftware.co.bw/api/fetchRegions";
  const [state, setState] = useState({
    "month": '',
    "year": '',
  })

  useEffect(() => {
    axios.post(regionUrl, {
      "accesstoken": token,
      "pickupUrl": ""
    }, axiosConfig).then((res) => {
      setRegions(res.data.data);
    }).catch((err) => console.log(err))
  }, [])

  const handleChange = (e) => {
    const { id, value } = e.target
    setState(prevState => ({
      ...prevState,
      [id]: value
    }))
  }

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalPassengers, setTotalPassengers] = useState(0);
  const token = localStorage.getItem('ccicanadaaccesstoken')

  let axiosConfig = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
    }
  };

  var body = {
    "accesstoken": token,
    "month": state.month,
    "year": state.year
  };

  const generatetable = (e) => {
    e.preventDefault();
    console.log(state.month);

    if (!state.month || !state.year) {
      MySwal.fire({
        title: <p>Please fill all fields</p>,
        didOpen: () => { },
      }).then(() => { });
    } else {
      MySwal.fire({
        didOpen: () => {
          MySwal.showLoading();
          axios.post(baseUrl, body, axiosConfig)
            .then((response) => {
              console.log("response received: ", response);
              let sortedArray;
              if (response.data.data) {
                response.data.data.forEach((resp, i) => {
                  if (resp.bookingregion != "") {
                    const regi = regions
                    const filter = regi.filter(res => res.id == resp.bookingregion);
                    response.data.data[i]['bookingregion'] = filter[0].region_name;
                  }
                });
                sortedArray = response.data.data;

              }
              setcust(sortedArray);
              console.log(sortedArray);



              // console.log("response received: ", response);
              // const sortedArray = response.data.data;
              // setcust(sortedArray);
              // console.log(sortedArray);
              MySwal.fire({
                title: <p>Table Generated</p>,
                didOpen: () => { },
              });
            })
            .catch((err) => {
              console.log("AXIOS ERROR: ", err);
              return MySwal.fire(err.response.data.message);
            });
        },
      }).then(() => {
        // Swal.close()
      });
    }
  };


  const columns = [
    {
      name: 'LOCATION',
      selector: (row) => row.bookingregion,
      sortable: true,
      width: "12rem",
      cellExport: (row) => row.bookingregion
    },

    {
      name: 'BOOKINGDATE',
      selector: (row) => row.booking_date,
      sortable: true,
      width: "12rem",
      cellExport: (row) => row.booking_date
    },
    {
      name: 'TOTAL PASSENGERS',
      selector: (row) => row.total_passengers,
      sortable: true,
      width: "12rem",
      cellExport: (row) => row.total_passengers
    },

  ];

  const data = cust_info;
  const tableData = {
    columns,
    data
  };


  return (
    <div>
      <Header />
      <br />
      <br />
      <br />
      <div id="content" className="main-content" style={{ width: '120px !important' }}>
        <div className="layout-px-spacing">
          <div className="content-container">
            <div className="col-left layout-top-spacing">
              <div className="col-left-content">
                <div className="header-container">
                  <header className="header navbar navbar-expand-sm">
                    <div className="d-flex">
                      <a href="javascript:void(0);" className="sidebarCollapse" data-placement="bottom">
                        <div className="bt-menu-trigger">
                          <span />
                        </div>
                      </a>
                      <br />
                      <br />
                      <br />
                      <div className="page-header">
                        <div className="page-title">
                          <h6 style={{ marginLeft: '0px', fontWeight: 'bold' }}>Reports</h6>
                        </div>
                      </div>
                    </div>
                  </header>
                </div>
                <div className="admin-data-content layout-top-spacing mywidth" style={{ marginLeft: '30px' }}>
                  <a href="/reports" className="btn btn-success">Monthly Summary</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="/userlocation" className="btn btn-primary">Users Per Location</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="/usermonth" className="btn btn-success">Users Per month</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a href="/expenses" className="btn btn-success">Expenses</a>
                  <div className="row">
                    <div className="admin-data-content layout-top-spacing">
                      <div className="row layout-top-spacing mywidth" id="cancel-row">
                        <div col-lg-10>
                          <label>Select Month</label>
                          <select className="form-control" value={state.month} onChange={handleChange} id="month" name="month">
                            <option value="">-Select-</option>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div col-lg-10>
                          <label>Select Year</label>
                          <select className="form-control" value={state.year} onChange={handleChange} id="year" name="year">
                            <option value=""> -Select-</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                          </select>
                        </div>

                        &nbsp;&nbsp;&nbsp;
                        <div>
                          <br />
                          <br />
                          <button onClick={generatetable} className="btn btn-primary" style={{ float: 'right', marginTop: '-10px' }}>Generate Table</button>
                        </div>

                        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                          <br />
                          <br />
                          <br />
                          <h6 style={{ marginLeft: '-18px', fontWeight: 'bold' }}>User Per Location</h6>
                          <div>
                            {/* <a className="btn btn-primary" style={{float: 'right'}}>Download as excel</a> */}
                          </div>
                          <br />
                          <br />
                          <br />
                          <div className="widget-content widget-content-area br-6">
                            <table id="html5-extension" className="table table-hover non-hover" style={{ width: '100%', marginLeft: '-40px' }}>
                              {cust_info == null ? <Spinner animation="border" /> : ""}
                              <DataTableExtensions {...tableData}>
                                <DataTable className=""
                                  columns={columns}
                                  data={data}
                                  noHeader
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  pagination
                                  exportHeaders={true}
                                  highlightOnHover
                                />
                              </DataTableExtensions>
                            </table>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>

  );

};

export default Userlocation;
