import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import Chart from 'react-apexcharts'
import axios from "axios";



function Home(){

    const [state , setState] = useState({
        email: '',
        password: '',
    })
const [cust_info, setcust] = useState([]);
const [booking_info, setbooking] = useState([]);
const token = localStorage.getItem('ccicanadaaccesstoken')
const bookingUrl = "https://cci.starlightsoftware.co.bw/api/fetchbookings";

let axiosConfig = {
  headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
  }
  };

    const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
      }

      const fetchurl = 'https://cci.starlightsoftware.co.bw/api/getSummary'
      var body = {
        "accesstoken": token
        };
       
  const getdashboardinfo = async (e) => {
        axios.post(fetchurl, body, axiosConfig)
        .then((response) => {
              console.log("response received: ", response);
              if(!response){
              setcust(0);
              }else{
              const sortedArray = response.data;
              setcust(sortedArray);
              setcust(sortedArray);
              console.log(Object.values(sortedArray.monthly_payments));
              console.log(sortedArray)
              }
        });
        };

        const getBookinginfo = async (e) => {
          axios.post(bookingUrl, body, axiosConfig)
          .then((response) => {
                console.log("response received: ", response);
                if(!response){
                  setbooking(0);
                }else{
                const sortedArray = response.data.data;
                const totalPriceSum = sortedArray.reduce((accumulator, currentItem) => {
                  // Convert the price to a number before adding
                  const priceAsNumber = parseFloat(currentItem.price);
                  return accumulator + (isNaN(priceAsNumber) ? 0 : priceAsNumber);
                }, 0);
                
                console.log(totalPriceSum);
                
                console.log(totalPriceSum);
                setbooking(totalPriceSum);
                setbooking(totalPriceSum);
                console.log(sortedArray)
                }
          });
          };

        useEffect(() => {
          getdashboardinfo();
          getBookinginfo();
          }, []);

          
          // console.log(Object.values(cust_info.monthly_payments));

            

          // const categories = cust_info ? cust_info.monthly_payments.map(entry => entry.month): '';
          // const totalBookingsData = cust_info? cust_info.monthly_payments.map(entry => entry.totalBookings):''; 
          
            const [chartData, setChartData] = useState({
              options: {
                chart: {
                  id: 'apexchart-example'
                },
                xaxis: {
                  categories: ['10/12/2023', '03/12/2023', '26/11/2023', '19/11/2023']
                }
              },
              series: [{
                name: 'series-1',
                data: [30, 40, 35, 50]
              }]
            });
    


      return (
              <div>
     <Header/>
     <div id="content" className="main-content" style={{width: '120px !important'}}>
        <div className="layout-px-spacing">
          <div className="content-container">
            <div className="col-left layout-top-spacing">
              <div className="col-left-content">
                <div className="header-container">
                  <header className="header navbar navbar-expand-sm">                                    
                    <div className="d-flex">
                      <a href="javascript:void(0);" className="sidebarCollapse" data-placement="bottom">
                        <div className="bt-menu-trigger">
                          <span />
                        </div>
                      </a>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <div className="page-header">
                        <div className="page-title">
                          <h3 style={{marginLeft: '-13px'}}>Home</h3>
                        </div>
                      </div>
                    </div>
                  </header>
                </div>
                <div className="admin-data-content layout-top-spacing mywidth   ">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                      <div className="widget widget-one">
                        <div className="widget-heading">
                          <h6 className="value" style={{fontWeight: 'bold'}}>Statistics</h6>
                        </div>
                        <div className="w-chart">
                          <div className="w-chart-section total-visits-content" style={{height: '150px'}}>
                            <div className="w-detail">
                              <p className="w-title">Registered users</p>
                              <br/>
                              <p className="w-stats">{cust_info.active_users_count}</p>
                            </div>
                            <div className="w-chart-render-one">
                              <div id="total-users" />
                            </div>
                          </div>
                          <div className="w-chart-section paid-visits-content">
                            <div className="w-detail">
                              <p className="w-title">Pending Reservations</p>
                              <br/>
                              <p className="w-stats">{cust_info.bookings_count}</p>
                            </div>
                            <div className="w-chart-render-one">
                              <div id="paid-visits" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                      <div className="widget widget-card-four">
                        <div className="widget-content">
                          <div className="w-header ">
                            <div className="widget-heading">
                              <h6 className="value" style={{fontWeight: 'bold'}}>Total Payments</h6>
                            </div>
                          </div>
                          <div className="w-content">
                            <div className="w-info">
                              <p className="value">CAD$ {booking_info} </p>
                            </div>
                          </div>
                          <div className="w-progress-stats">                                            
                            <div className>
                              <div className="w-icon">
                                {/* <p>20 Successful Bookings</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                      <div className="widget widget-chart-three">
                        <div className="">
                          <div className>
                            <h6 className='value' style={{fontWeight: 'bold', marginLeft: '15px'}}>Bookings </h6>
                          </div>
                        
                        </div>
                        <div className="widget-content">
                        <Chart
                            options={chartData.options}
                            series={chartData.series}
                            type="bar" // Specify the chart type (e.g., "line", "bar", etc.)
                            height={350} // Specify the height of the chart
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
     <Footer/>
              </div>
               
        );
 
  };

export default Home;
