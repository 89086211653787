import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from "axios";
import dayjs from "dayjs";
import Spinner from 'react-bootstrap/Spinner';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";



function Schedulebreakdown(){
const urlParams = new URLSearchParams(window.location.search);
const history = useHistory();

const goBack = () => {
  history.goBack();
};
const myidd = urlParams.get('myidd');
const MySwal = withReactContent(Swal)
const [cust_info, setcust] = useState([]);
const baseUrl = "https://cci.starlightsoftware.co.bw/api/fetchschedulesById";
const token = localStorage.getItem('ccicanadaaccesstoken')

let axiosConfig = {
headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
  }
};

var body = {
      "accesstoken": token,
      "scheduleID": myidd
      };
     
const getdashboardinfo = async (e) => {
      axios.post(baseUrl, body, axiosConfig)
      .then((response) => {
            console.log("response received: ", response);
            if(!response){
            setcust(0);
            }else{
            const sortedArray = response.data.data;
            const pills = JSON.parse(sortedArray[0].approvedschedules);
            setcust(pills);
            setcust(pills);
            console.log(pills);
            }
      });
      };


      useEffect(() => {
      getdashboardinfo();
      // getitemsinfo();
      }, []);

      return (
     <div>
     <Header/>
     <br/>
     <br/>
     <br/>

     <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="content-container">
            <div className="col-left layout-top-spacing">
              <div className="col-left-content">
                <div className="header-container">
                  <header className="header navbar navbar-expand-sm">                                    
                    <div className="d-flex">
                      <a href="javascript:void(0);" className="sidebarCollapse" data-placement="bottom">
                        <div className="bt-menu-trigger">
                          <span />
                        </div>
                      </a>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <div className="page-header">
                        <div className="page-title">
                        </div>
                      </div>
                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button onClick={goBack} className="btn btn-danger">Go Back</button>
                  </header>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12  card">
                  <div className="user-profile layout-spacing p-10" style={{padding: '10px'}}>
                    <div className="widget-content widget-content-area" style={{padding: '10px'}}>
                      <div className="d-flex justify-content-between">
                        <h3 className>Booking Information</h3>
                        {/* <a href="#" className="mt-2 edit-profile"> <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-3"><path d="M12 20h9" /><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" /></svg></a> */}
                      </div>
                      <div className="text-center user-info">
                      </div>
                      {cust_info.map((item, index) => (
                        <div key={index} className="table-container">
                            <h2>Schedule {index + 1}</h2>
                            <table className="table">
                            <thead>
                                <tr>
                                <th>User</th>
                                <th>Address</th>
                                <th>Region</th>
                                {/* Add more table headers as needed */}
                                </tr>
                            </thead>
                            <tbody>
                                {item.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td>{row.firstname}</td>
                                    <td>{row.bookingaddress}</td>
                                    <td>{row.region}</td>
                                    {/* Add more table cells as needed */}
                                </tr>
                                ))}
                            </tbody>
                            </table>
                        </div>
                        ))}



                    </div>
                  </div>
                </div>
              </div></div></div></div></div>
       
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Schedulebreakdown;
