import React, {useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from "axios";
import dayjs from "dayjs";
import Spinner from 'react-bootstrap/Spinner';
import withReactContent from 'sweetalert2-react-content'
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";


function Users(){

  const MySwal = withReactContent(Swal)
  const [cust_info, setcust] = useState([]);
  // const baseUrl = "http://localhost:9600/api/get";
  const baseUrl = "https://cci.starlightsoftware.co.bw/api/get";

  const token = localStorage.getItem('ccicanadaaccesstoken')
  
  let axiosConfig = {
  headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
  }
  };
  
  
  var body = {
        "accesstoken": token
        };
       
  const getdashboardinfo = async (e) => {
        axios.post(baseUrl, body, axiosConfig)
        .then((response) => {
              console.log("response received: ", response);
              if(!response){
              setcust(0);
              }else{
              const sortedArray = response.data.data;
              const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
              setcust(numDescending);
              setcust(numDescending);
              console.log(numDescending)
              }
        });
        };
  
  
        useEffect(() => {
        getdashboardinfo();
        // getitemsinfo();
        }, []);
  
        const columns = [
              {
                    name: 'REG DATE',
                    selector: (row) =>  dayjs(row.created_at).format("DD MMM YY"),
                    sortable: true,
                    width: "9rem",
                    cellExport: (row) =>  dayjs(row.created_at).format("DD MMM YY")
                },
  
              {
                name: 'NAME',
                selector: (row) => row.firstname,
                sortable: true,
                width: "15rem",
              cellExport: (row) => row.firstname
            },
              {
              name: 'REGION',
              cell: (row) => row.region,
              sortable: true,
              width: '10rem',
              cellExport: row => row.region 
              },
              {
                    name: 'PHONE',
                    cell: (row) => row.phone,
                    sortable: true,
                    width: '8rem',
                    cellExport: row => row.phone 
                    },
              {
                    name: 'STATUS',
                    cell: (row) => row.is_active=='1'? 'Active': 'Inactive',
                    sortable: true,
                    width: '8rem',
                    cellExport: row => row.is_active 
                    },
              {
              name: 'ACTION',
              cell: (row) => (
                    <button className='btn btn-sm btn-danger' onClick={update} id={row.id}> View</button>
              ),
              sortable: true,
              width: '8rem',
              }
          ];
            const data=cust_info;
            const tableData = {
              columns,
              data
            };
      
            const update = async (e) => {
              const itemID = e.target.id;
              console.log(itemID)
              if(!e.target.id){
                return MySwal.fire('No ID for this request, please refresh your browser or log out.').then(() => {
                })
                }else{
              const newid = e.target.id
              window.location.href = "viewuser?myidd=" + newid;
            }
            };
  


      return (
     <div>
     <Header/>
        <br/>
        <br/>
        <br/>
     <div id="content" className="main-content">
        <div className="layout-px-spacing">
          <div className="content-container">
            <div className="col-left layout-top-spacing">
              <div className="col-left-content">
                <div className="header-container">
                  <header className="header navbar navbar-expand-sm">                                    
                    <div className="d-flex">
                      <a href="javascript:void(0);" className="sidebarCollapse" data-placement="bottom">
                        <div className="bt-menu-trigger">
                          <span />
                        </div>
                      </a>
                      <div className="page-header">
                        <div className="page-title">
                          <h6 style={{marginLeft: '18px', fontWeight: 'bold'}}>All Users</h6> 
                        </div>
                        <div>
                        </div>
                      </div>
                    </div>
                  </header>
                </div>
                <br />
                <br />
                <div className="row">
                  <div className="col-6">
                    <a href='/adduser'  style={{marginLeft: '45px'}} className="btn btn-success"> Add  New Users</a>
                  </div>
                </div>
                <div className="admin-data-content layout-top-spacing">
                  <div className="row layout-top-spacing mywidth" id="cancel-row">
                    <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                      <div className="widget-content widget-content-area br-6">
                        <table id="html5-extension" className="table table-hover non-hover" style={{width: '100%'}}>
                        <DataTableExtensions {...tableData}>
                                <DataTable className=""
                                  columns={columns}
                                  data={data}
                                  noHeader
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  pagination
                                  exportHeaders= {true}
                                  highlightOnHover
                                />
                              </DataTableExtensions>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div></div></div></div></div>
       
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Users;
