import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home from './component/home';
import Bookings from './component/bookings';
import Payments from './component/payments';
import Viewregions from './component/viewregions';
import Allbookings from './component/allbookings';
import Users from './component/users';
import Deleterequest from './component/deleterequest';
import Adduser from './component/adduser';
import Authentication from './component/authentication';
import Allusers from './component/allusers';
import Signin from './component/signin';
import Viewbooking from './component/viewbooking';
import Viewuser from './component/viewuser';
import Expenses from './component/expenses';
import Reports from './component/reports';
import Userlocation from './component/userlocation';
import Viewpickupregions from './component/viewpickupregions';
import Usermonth from './component/usermonth';
import Allpayments from './component/allpayments';
import Schedule from './component/schedule';
import Settings from './component/settings';
import ViewSchedules from './component/viewschedules';
import Verifyauth from './component/verifyauth';
import Forgotpassword from './component/forgotpassword';
import Schedulebreakdown from './component/schedulebreakdown';
import Signup from './component/signout';
import Changepassword from './component/users/changepassword';

export default function BasicExample() {
  return (
    <Router>
      <div>
       

        <Switch>
          <Route exact path="/" component={Signin}/>
          <Route exact path="/home" component={Home}/>
          <Route exact path="/signup" component={Signup}/>
          <Route exact path="/deleterequest" component={Deleterequest}/>
          <Route exact path="/viewbooking" component={Viewbooking}/>
          <Route exact path="/viewpickupregions" component={Viewpickupregions}/>
          <Route exact path="/Verifyauth" component={Verifyauth}/>
          <Route exact path="/viewschedules" component={ViewSchedules}/>
          <Route exact path="/schedulebreakdown" component={Schedulebreakdown}/>
          <Route exact path="/forgotpassword" component={Forgotpassword}/>
          <Route exact path="/viewuser" component={Viewuser}/>
          <Route exact path="/viewregions" component={Viewregions}/>
          <Route exact path="/expenses" component={Expenses}/>
          <Route exact path="/userlocation" component={Userlocation}/>
          <Route exact path="/usermonth" component={Usermonth}/>
          <Route exact path="/reports" component={Reports}/>
          <Route exact path="/bookings" component={Bookings}/>
          <Route exact path="/schedule" component={Schedule}/>
          <Route exact path="/authentication" component={Authentication}/>
          <Route exact path="/payments" component={Payments}/>
          <Route exact path="/allbookings" component={Allbookings}/>
          <Route exact path="/users" component={Users}/>
          <Route exact path="/adduser" component={Adduser}/>
          <Route exact path="/allusers" component={Allusers}/>
          <Route exact path="/payments" component={Payments}/>
          <Route exact path="/allpayments" component={Allpayments}/>
          <Route exact path="/settings" component={Settings}/>
          <Route path="/changepassword" component={Changepassword}/>
          {/* <Route path="/payments" component={Payments}/> */}

         
            
          
        </Switch>
      </div>
    </Router>
  );
}



